<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("reception_pending.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("reception_pending.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  {{ $t("reception_pending.reception") }}
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3) || showAction(4)"
              @click="changeAction(1)"
            >
              <i class="feather icon-arrow-left mr-1"></i>
              {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>

      <div class="contentbar">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title mb-0">
                      <i class="feather icon-truck mr-2"></i
                      >{{ $t(titleAction) }}
                    </h5>
                  </div>
                  <div class="col-md-4">
                    <v-autocomplete
                      :disabled="!showAction(1)"
                      v-if="isMoreThanTwoBranchOffices"
                      @change="listAllByBranchOffice()"
                      auto-select-first
                      :items="branchOffices"
                      :no-data-text="$t('label.no_results')"
                      attach
                      v-model="branchOffice"
                      clearable
                      label="Sucursal de Origen"
                    >
                    </v-autocomplete>
                  </div>
                </div>
              </div>

              <div v-show="showAction(1)">
                <ReceptionTableComponent
                  ref="tableReception"
                  :headers="headers"
                  :isButton="true"
                  :isSend="withPermissionName(permissions.REC_PEN_SHIP_01)"
                  @receiveShipment="listAllOrderStockByShipment"
                  @excelTable="excelTable"
                />
              </div>

              <!-- Dispensacion informacion de la receta -->
              <div v-if="showAction(2) || showAction(3) || showAction(4)">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <h5
                          :class="{
                            'text-fmedical-green': leftovers == 0,
                            'text-fmedical-yellow': leftovers > 0,
                          }"
                        >
                          <i
                            class="feather icon-plus-circle"
                            aria-hidden="true"
                          ></i>
                          {{ $t("reception_pending.leftovers") }}
                          {{ leftovers }}
                        </h5>
                      </div>
                      <div class="col-md-4">
                        <h5
                          :class="{
                            'text-fmedical-green': missing == 0,
                            'text-fmedical-red': missing > 0,
                          }"
                        >
                          <i
                            class="feather icon-minus-circle"
                            aria-hidden="true"
                          ></i>
                          {{ $t("reception_pending.missing") }} {{ missing }}
                        </h5>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 col-lg-6 col-xl-4 pb-0 pt-0">
                        <v-alert
                          class="card card-detail icon"
                          text
                          color="#023047"
                        >
                          <i
                            class="feather icon-map-pin text-fmedical-blue"
                          ></i>
                          <strong>
                            <span> {{ $t("reception_pending.origin") }}</span>
                          </strong>
                          <p>{{ model.branchOfficeOrigin }}</p>
                        </v-alert>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 pb-0 pt-0">
                        <v-alert
                          class="card card-detail icon"
                          text
                          color="#023047"
                        >
                          <i
                            class="feather icon-user text-fmedical-blue mr-1"
                          ></i>
                          <strong>
                            <span> {{ $t("reception_pending.driver") }}</span>
                          </strong>
                          <p>{{ model.driver }}</p>
                        </v-alert>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 pb-0 pt-0">
                        <v-alert
                          class="card card-detail icon"
                          text
                          color="#023047"
                        >
                          <i
                            class="feather icon-calendar text-fmedical-blue mr-1"
                          ></i>
                          <strong>
                            <span>
                              {{ $t("reception_pending.date_sent") }}</span
                            >
                          </strong>
                          <p>{{ model.shippingDateText }}</p>
                        </v-alert>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-2 pb-0 pt-0">
                        <v-alert
                          class="card card-detail icon"
                          text
                          color="#023047"
                        >
                          <i
                            class="feather icon-truck text-fmedical-blue mr-1"
                          ></i>
                          <strong>
                            <span> {{ $t("reception_pending.plate") }}</span>
                          </strong>
                          <p>{{ model.licencePlate }}</p>
                        </v-alert>
                      </div>
                    </div>
                    <div class="row justify-end">
                      <div
                        class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pr-6 text-right"
                      >
                        <v-text-field
                          hide-details="auto"
                          v-model="search"
                          :label="$t('table.search')"
                        ></v-text-field>
                      </div>
                    </div>

                    <div id="form">
                      <validation-observer ref="observer" v-slot="{ invalid }">
                        <form @submit.prevent="addRequesition">
                          <div class="row p-3">
                            <div class="table-responsive br">
                              <table class="table table-hover table-striped">
                                <thead class="thead-dark">
                                  <tr class="regular">
                                    <th scope="col">#</th>
                                    <th scope="col" class="text-center">
                                      {{ $t("reception_pending.financing") }}
                                    </th>
                                    <th scope="col" style="width:10%">
                                      {{ $t("reception_pending.causes") }}
                                    </th>
                                    <th scope="col" style="width:30%">
                                      {{ $t("reception_pending.product") }}
                                    </th>
                                    <th scope="col" class="text-center">
                                      {{ $t("reception_pending.expiration") }}
                                    </th>
                                    <th scope="col" class="text-center">
                                      {{ $t("reception_pending.lot") }}
                                    </th>
                                    <th scope="col" class="text-center">
                                      {{
                                        $t("reception_pending.quantity_shipped")
                                      }}
                                    </th>
                                    <th scope="col" class="text-center">
                                      {{
                                        $t(
                                          "reception_pending.quantity_received"
                                        )
                                      }}
                                    </th>
                                    <th scope="col" class="text-center">
                                      Estado
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-if="
                                      filteredItems.length === 0 && search != ''
                                    "
                                  >
                                    <td colspan="8" class="text-center">
                                      {{ $t("global.not_result_card") }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-for="(item, index) in filteredItems"
                                    :key="index"
                                  >
                                    <td>{{ index + 1 }}</td>
                                    <td class="text-center">
                                      {{ item.fiscalFoundKey }}
                                    </td>
                                    <td>{{ item.productKey }}</td>
                                    <td>{{ item.productDescription }}</td>
                                    <td class="text-center">
                                      {{ item.expirationDate }}
                                    </td>
                                    <td class="text-center">{{ item.lot }}</td>
                                    <td class="text-center" style="width:16%">
                                      {{ item.quantity }}
                                    </td>
                                    <td class="text-center" style="width:9%">
                                      <validation-provider
                                        v-slot="{ errors }"
                                        name="amount_received"
                                        :rules="
                                          `required|min_value:0|max_value:${item.quantity}`
                                        "
                                      >
                                        <v-text-field
                                          disabled
                                          class="thin-number"
                                          v-model="item.quantityReceived"
                                          type="number"
                                          required
                                          min="0"
                                          :error-messages="errors"
                                          oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
                                          name="quantityReceived"
                                          @change="calculateStatus"
                                        >
                                        </v-text-field>
                                      </validation-provider>
                                      <small
                                        :class="{
                                          'text-fmedical-red':
                                            item.quantityReceived <
                                            item.quantity,
                                        }"
                                      >
                                        {{ messageQuantity(item) }}
                                      </small>
                                    </td>
                                    <td class="text-center" style="width:9%">
                                      <v-chip
                                        v-if="item.warehouseId == 1"
                                        color="green"
                                        dark
                                        class="m-1"
                                      >
                                        Producto activo
                                      </v-chip>
                                      <v-chip
                                        v-else
                                        color="info"
                                        dark
                                        class="m-1"
                                      >
                                        Etiquetado como no conforme
                                      </v-chip>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="row p-1">
                            <div class="col-md-12">
                              <validation-provider
                                v-slot="{ errors }"
                                name="comment"
                                rules="max:250|regex:^[0-9A-ZÁÉÍÑÓÚÜ]+(?:[\s][0-9A-ZÁÉÍÑÓÚÜ]+)*$"
                              >
                                <v-textarea
                                  oninput="this.value = this.value.toUpperCase()"
                                  outlined
                                  v-model="model.commentReception"
                                  :counter="250"
                                  name="comment"
                                  :label="$t('label.comment')"
                                  :error-messages="errors"
                                  rows="4"
                                  row-height="20"
                                ></v-textarea>
                              </validation-provider>
                            </div>
                            <div class="col-12 text-right pt-0">
                              <button
                                class="btn btn-outline-success"
                                v-if="!showAction(4)"
                                type="submit"
                                :disabled="invalid || sending"
                              >
                                <i class="feather icon-save mr-1"></i>
                                {{ $t("global.save_reception") }}
                              </button>
                            </div>
                          </div>
                        </form>
                      </validation-observer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex implementacion
import { mapState, mapMutations } from "vuex";
import { UtilFront, PermissionConstants } from "@/core";
import { ReceptionRequest, ExcelRequest } from "@/core/request";
import ReceptionTableComponent from "@/components/reception/ReceptionTableComponent";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { RECEPTION_PENDING } from "@/core/DataTableHeadersLocale";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";

export default {
  data() {
    return {
      title: "reception_pending.pending",
      list: [],
      headers: RECEPTION_PENDING(),
      search: " ",
      action: 1,
      sending: false,
      dialog: false,
      isView: false,
      model: {},
      notifier: {
        notifying: false,
        type: "",
        message: "",
      },
      branchOffice: {},
      branchOffices: [],
      isFull: false,
      isIncomplete: false,
      isMoreThan: false,
      statusText: "Completo",
      message: "",
      leftovers: 0,
      missing: 0,
      loading: null,
      search: "",
      permissions: PermissionConstants,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ReceptionTableComponent,
    AlertMessageComponent,
  },
  async created() {
    this.branchOffice = this.branchOfficeState;
    this.updateAction(1);
    this.action = 1;
    await this.listAllByBranchOffice();
  },
  computed: {
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    titleAction() {
      const title = {
        1: "reception_pending.pending_receipts",
        2: "reception_pending.shipping_information",
        3: "reception_pending.modify_receptio",
        4: "reception_pending.reception_information"
      }
      return title[this.action] || "Listado";
    },
    isMoreThanTwoBranchOffices() {
      return this.branchOffices.length > 1;
    },
    filteredItems() {
      return this.model.orderStockList.filter((item) => {
        return (
          item.productKey.toLowerCase().indexOf(this.search.toLowerCase()) >
            -1 ||
          item.fiscalFoundKey.toLowerCase().indexOf(this.search.toLowerCase()) >
            -1 ||
          item.productDescription
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1 ||
          item.lot.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  methods: {
    withPermissionName(permissions) {
      return UtilFront.withPermissionName(permissions);
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    // Funcion para el cambio de sucursal global en el state
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION", // Funcion para cambiar el valor del action global
    }),
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      if (action == 1) {
        this.$refs.observer.reset();
        this.model = {};
        this.sending = false;
      }
      this.action = action;
      this.isView = action == 4;
    },
    async listAllByBranchOffice() {
      try {
        this.loading = UtilFront.getLoadding();
        const response = await ReceptionRequest.listAllByBranchOffice({
          idBranchOffice: this.branchOfficeState.idBranchoffice,
        });
        const { status, data } = response;
        if (status === 200) {
          this.$refs.tableReception.reloadComponent(data);
        }
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.loading.hide();
      }
    },
    async addRequesition(e) {
      e.preventDefault();
      const { isConfirmed } = await this.$SwalConfirm.fire({
        text: "La recepción será guardada.",
      });
      if (!isConfirmed) {
        return;
      }
      this.loading = UtilFront.getLoadding();
      const isQuantityValid = this.model.orderStockList.every(
        (stock) => stock.quantityReceived <= stock.quantity
      );
      if (isQuantityValid && !this.sending) {
        this.sending = true;
        const comment = this.model.commentReception ?? "";
        const idUser = UtilFront.getDataUser().idUser;
        const orderStockList = JSON.parse(
          JSON.stringify(this.model.orderStockList)
        );
        const payload = {
          idBranchOffice: this.branchOffice.idBranchoffice,
          idUser,
          comment,
          idReception: this.model.idReception,
          orderStockList,
          shipmentId: this.model.shipmentId,
        };
        try {
          const res = await ReceptionRequest.addRequesition(payload);
          console.log(res);
          const { status } = res;
          if (status == 200) {
            this.loading.hide();
            await this.listAllByBranchOffice();
            this.changeAction(1);
            this.alert("success", "Add_Success_0001");
          }
        } catch (error) {
          console.log(error);
          this.alert("error", error.message);
        } finally {
          this.loading.hide();
        }
      } else {
        this.loading.hide();
        this.alert("error", "Empty_Fields_0003");
      }
    },
    listAllOrderStockByShipment(item) {
      this.loading = UtilFront.getLoadding();
      ReceptionRequest.listAllOrderStockByShipment({
        shipmentId: item.shipmentId,
      })
        .then((response) => {
          const { status: responseStatus, data: responseData } = response;
          if (responseStatus === 200) {
            const updatedOrderStockList = responseData.orderStockList.map(
              (payload) => ({
                ...payload,
                quantityReceived: Number(payload.quantity),
              })
            );
            const updatedModel = {
              ...responseData,
              orderStockList: updatedOrderStockList,
            };
            this.model = updatedModel;
            this.calculateStatus();
            this.changeAction(2);
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    messageQuantity(item) {
      let result = item.quantityReceived - item.quantity;
      if (result == 0) {
        return "";
      } else if (result < 0) {
        return this.$t("reception_pending.missing_m", {
          name: result * -1,
        });
      }
    },
    calculateStatus() {
      this.missing = 0;
      this.leftovers = 0;
      this.model.orderStockList.forEach((item) => {
        let result = item.quantityReceived - item.quantity;
        if (result < 0) {
          this.missing = this.missing + result * -1;
        } else if (item.quantityReceived > item.quantity) {
          this.leftovers = this.leftovers + result;
        }
      });
    },
    async excelTable() {
      try {
        this.loading = UtilFront.getLoadding();
        await ExcelRequest.reportReceptionPending({
          id: this.branchOffice.idBranchoffice,
        });
        this.loading.hide();
        this.$toast.success("Reporte generado (recepcionesPendientes.xlsx)");
      } catch (error) {
        this.loading.hide();
      }
    },
  },
  watch: {
    async branchOfficeState() {
      this.branchOffice = this.branchOfficeState;
      await this.listAllByBranchOffice();
    },
    action() {
      this.updateAction(this.action);
    },
    "$i18n.locale": function() {
      this.headers = RECEPTION_PENDING();
    },
  },
};
</script>

<style scoped>
p,
span {
  font-family: "Roboto-Regular" !important;
}
</style>
